const FirebaseConfig = {
  apiKey: "AIzaSyDsvWjZgEmKK4aNiOYtcbqXnOLTVFhmvyE",
  authDomain: "nasappwf.firebaseapp.com",
  databaseURL: "https://nasappwf-default-rtdb.firebaseio.com",
  projectId: "nasappwf",
  storageBucket: "nasappwf.appspot.com",
  messagingSenderId: "303850033163",
  appId: "1:303850033163:web:2f7a9b6b02031969a2b70e",
  measurementId: "G-3W9QHH4J5V"
};

export default FirebaseConfig

