import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import firebaseConfig from 'configs/FirebaseConfig';
import 'firebase/messaging';

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const messaging = firebase.messaging()
const auth = firebase.auth();
const projectFirestore = firebase.firestore();
const currentUser = auth.currentUser
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

messaging.getToken(messaging, { vapidKey: 'BAcMkGHnT9pvE9YRoYLFSaJQVUkdRy5Bbh9Pnwq3ZFz7105mDpPFoiuzp4Wsj0Mwn7Q4hqCRRPw-EujqYf-kg0E' }).then((currentToken) => {
	if (currentToken) {
	  // Send the token to your server and update the UI if necessary
	  // ...

	  console.log("current Token:", currentToken)
	} else {
	  // Show permission request UI
	  console.log('No registration token available. Request permission to generate one.');
	  // ...
	}
  }).catch((err) => {
	console.log('An error occurred while retrieving token. ', err);
	// ...
  });

export {
	db,
	messaging,
	projectFirestore,
	auth,
	currentUser,
	googleAuthProvider,
	facebookAuthProvider,
	twitterAuthProvider,
	githubAuthProvider,
	phoneAuthProvider
};